@tailwind base;
@tailwind components;
@tailwind utilities;


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.thisvideos{
  gap: 25px;
}

@media screen and (max-width: 768px) {
  .carousel .control-arrow {
    font-size: 10px;
    margin: 0 5px;
  }
}
@media screen and (max-width: 768px) {
  .asdasd{
    width: 700px !important;
  }
  .videos{
    width: 200px !important;
    height: 90%;
  ;
  }
}
@media screen and (max-width: 588px) {
  .asdasd{
    width: 100vw !important;
  }
  .videos{
    width: 400px !important;
    height: 100% !important;
  ;
  }
}
.asdasd{
  width: 1000px;
}
